<template>
	<div class='pt30 pb10'>
		<div class="mr16 ml16 flex h190 mb20" v-for="(item,i) in list" :key="i">
			<img src="@/assets/images/aboutUs/chairmanImg.png" class="h190 flex1">
			<div class="flex1 flex row-column row-center col-center" style="background: #f9f9f9;">
				<div class="fs16 fw-600">{{item.expertName}}</div>
				<div class="fs11 color9D mt30">{{item.expertPosition}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getExpert } from '@/api'
	export default {
		data() {
			return {
				list:[]
			};
		},

		components: {},

		created() {
			this.$store.commit('editNavUrl',this.$route.path)
			this.getExpert()
		},

		mounted() {},

		methods: {
			async getExpert() {
				const res = await getExpert()
				this.list=res.data
			}
		}
	}
</script>
<style lang='scss' scoped>
</style>
